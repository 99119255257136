import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

type DataProps = {
  contentfulPolicy: {
    metaDescription: {
      metaDescription: string
    }
    seoTitle: string
    headline: string
    copy: {
      childMarkdownRemark: {
        html: string
      }
    }
  }
}

const Policies: React.FC<PageProps<DataProps>> = ({
  data: {
    contentfulPolicy: { headline, copy, seoTitle, metaDescription },
  },
  path,
}) => (
  <Layout>
    <Seo title={seoTitle} description={metaDescription.metaDescription} />
    <div className="bg-nmg-grey">
      <Container>
          <h1 className="font-headline font-bold text-5xl pt-7 leading-header text-center uppercase">
            {headline}
          </h1>
          <div
            className="copy-body md:max-w-3xl md:mx-auto -mt-7"
            dangerouslySetInnerHTML={{
              __html: copy.childMarkdownRemark.html,
            }}
          />
      </Container>
    </div>
  </Layout>
)

export default Policies

export const query = graphql`
  query PolicyQuery($slug: String!) {
    contentfulPolicy(slug: { eq: $slug }) {
      headline
      metaDescription {
        metaDescription
      }
      seoTitle
      copy {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
